import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { CometChat } from '@cometchat-pro/chat';
import { DomSanitizer } from '@angular/platform-browser';

// Const
import { CALL_SCREEN_ACTIONS } from '../../const/constants';

// Helper
import { Helper } from '../../helpers/helper';

// Manager
import { CallScreenManager } from '../../managers/cometchat-call-screen-manager';

// Services
import { AudioService } from 'src/app/core/providers/audio.service';

@Component({
  selector: 'app-calling-screen',
  templateUrl: './calling-screen.component.html',
  styleUrls: ['./calling-screen.component.scss'],
})
export class CallingScreenComponent implements OnInit, OnChanges {
  @Input() inProgressCall;
  @Input() incomingScreen?;

  @Output() actionPerformed = new EventEmitter<{
    action: string;
    payload?: object | any;
  }>();

  callingScreenManager: CallScreenManager = new CallScreenManager();

  toUser?: CometChat.User;
  name?: string;
  image?: any;
  callInProgress: boolean = false;
  outgoingScreen = false;

  json = JSON;

  constructor(
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private ringTone: AudioService
  ) {}

  ngOnInit() {
    this.callingScreenManager.isLoggedIn(this.isChatReady);
  }

  ngOnChanges() {
    let tempCall = new CometChat.Call('', '', '');
    if (this.inProgressCall && !(typeof this.inProgressCall === 'object')) {
      this.inProgressCall = Object.assign(
        tempCall,
        JSON.parse(this.inProgressCall)
      ) as CometChat.Call;

      let tempUser = new CometChat.User({});

      if (this.incomingScreen) {
        this.toUser = Object.assign(
          tempUser,
          (this.inProgressCall as CometChat.Call).getSender() as CometChat.User
        );
        this.name = this.toUser.getName();
        this.image = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.toUser.getAvatar()
            ? this.toUser.getAvatar()
            : Helper.getSVGAvatar(
                this.toUser.getUid(),
                this.toUser.getName().substr(0, 1)
              )
        );
      } else {
        this.toUser = Object.assign(
          tempUser,
          (this
            .inProgressCall as CometChat.Call).getReceiver() as CometChat.User
        );
        this.name = this.toUser.getName();
        this.image = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.toUser.getAvatar()
            ? this.toUser.getAvatar()
            : Helper.getSVGAvatar(
                this.toUser.getUid(),
                this.toUser.getName().substr(0, 1)
              )
        );
      }

      this.callInProgress = true;

      if (this.incomingScreen) {
        this.incomingScreen = true;
      } else {
        this.outgoingScreen = true;
      }
    }
  }
  endIncomingCall($event) {
    this.ringTone.stop();

    try {
      let sessionID = (this.inProgressCall as CometChat.Call).getSessionId();
      CometChat.rejectCall(sessionID, CometChat.CALL_STATUS.REJECTED).then(
        (call) => {
          this.hideCallScreen();
        },
        (error) => {
          this.hideCallScreen();
        }
      );
    } catch (e) {
      this.hideCallScreen();
    }
  }

  acceptIncomingCall($event) {
    this.ringTone.stop();

    try {
      let sessionID = (this.inProgressCall as CometChat.Call).getSessionId();
      CometChat.acceptCall(sessionID).then(
        (call) => {
          // start the call using the startCall() method
          let sessionID = call.getSessionId();
          this.outgoingScreen = false;
          this.incomingScreen = false;
          this.cdRef.detectChanges();
          CometChat.startCall(
            sessionID,
            document.getElementById('callScreen'),
            new CometChat.OngoingCallListener({
              onUserJoined: (user) => {},
              onUserLeft: (user) => {},
              onCallEnded: (call) => {
                this.endIncomingCall(call);
              },
            })
          );
        },
        (error) => {
          this.hideCallScreen();
        }
      );
    } catch (e) {
      this.hideCallScreen();
    }
  }

  endOutGoingCall($event) {
    this.ringTone.stop();

    CometChat.rejectCall(
      this.inProgressCall.getSessionId(),
      CometChat.CALL_STATUS.CANCELLED
    ).then(
      (rejectedCall) => {
        this.inProgressCall = rejectedCall;
        this.callInProgress = false;
        this.hideCallScreen();
      },
      (error) => {}
    );
  }

  callback = (event: { action: string; payload?: object | any }) => {
    switch (event.action) {
      case CALL_SCREEN_ACTIONS.INCOMING_CALL_RECEIVED: {
        this.inProgressCall = event.payload.call;
        this.callInProgress = true;
        let sessionID = (this.inProgressCall as CometChat.Call).getSessionId();

        let tempUser = new CometChat.User({});
        this.toUser = Object.assign(
          tempUser,
          (this.inProgressCall as CometChat.Call).getSender() as CometChat.User
        );
        this.name = this.toUser.getName();
        this.image = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.toUser.getAvatar()
            ? this.toUser.getAvatar()
            : Helper.getSVGAvatar(
                this.toUser.getUid(),
                this.toUser.getName().substr(0, 1)
              )
        );
        this.incomingScreen = true;
        this.outgoingScreen = false;
        this.cdRef.detectChanges();

        break;
      }
      case CALL_SCREEN_ACTIONS.OUTGOING_CALL_REJECTED: {
        this.inProgressCall = event.payload.call;
        this.callInProgress = false;
        this.hideCallScreen();
        break;
      }
      case CALL_SCREEN_ACTIONS.INCOMING_CALL_CANCELLED: {
        this.inProgressCall = event.payload.call;
        this.hideCallScreen();
        break;
      }
      case CALL_SCREEN_ACTIONS.OUTGOING_CALL_ACCEPTED: {
        this.outgoingScreen = false;
        this.incomingScreen = false;
        this.cdRef.detectChanges();
        CometChat.startCall(
          (this.inProgressCall as CometChat.Call).getSessionId(),
          document.getElementById('callScreen'),
          new CometChat.OngoingCallListener({
            onUserJoined: (user) => {},
            onUserLeft: (user) => {},
            onCallEnded: (call) => {
              this.hideCallScreen();
            },
          })
        );
        break;
      }
    }
  };

  hideCallScreen() {
    this.toUser = undefined;
    this.outgoingScreen = false;
    this.incomingScreen = false;
    this.callInProgress = false;
    this.inProgressCall = undefined;
    this.cdRef.detectChanges();
    this.actionPerformed.emit({
      action: CALL_SCREEN_ACTIONS.HIDE_SCREEN,
      payload: {},
    });
  }

  isChatReady = (user?: CometChat.User, error?) => {
    if (user) {
      this.callingScreenManager.attachListener(this.callback);
    }
  };
}
