import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Services
import { ApiService } from './providers/api.service';
import { AuthService } from './providers/auth.service';
import { CometchatService } from './providers/cometchat.service';
import { AudioService } from './providers/audio.service';

const PROVIDERS = [ApiService, AuthService, CometchatService, AudioService];

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [...PROVIDERS],
})
export class CoreModule {}
