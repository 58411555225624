export const environment = {
  production: false,
  api: {
    url: 'https://app.mootiv.app/api',
    version: '/v2.0',
  },
  cometChat: {
    appId: '1903101f8a9ff93',
    apiKey: '2956f8dfe3e34cffec85d01b6f7f16804c73f2ea',
    region: 'eu',
  },
};
