import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

// Const
import { NAVIGATION_MENU_ACTIONS } from '../../const/constants';

@Component({
  selector: 'cometchat-bottom-navigation',
  templateUrl: './bottom-navigation.component.html',
  styleUrls: ['./bottom-navigation.component.scss'],
})
export class BottomNavigationComponent implements OnInit {
  @Output() actionPerformed = new EventEmitter<{
    action: string;
    payload?: object;
  }>();

  selectedTab = 'conversation';

  constructor(private router: Router) {}

  ngOnInit() {}

  selectTab = (item) => {
    this.selectedTab = item;
    this.actionPerformed.emit({
      action: NAVIGATION_MENU_ACTIONS.TAB_CHANGED,
      payload: { item },
    });
  };

  logout() {
    this.router.navigate(['logout']);
  }
}
