import { Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'cometchat-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnChanges {
  @Input() image: any;
  @Input() cornerRadius?: any;
  @Input() borderWidth?: any;
  @Input() borderColor?: any;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges() {
    this.image = this.sanitizer.bypassSecurityTrustResourceUrl(this.image);
  }

  getMyStyle = () => {
    return {
      border:
        (this.borderWidth ? this.borderWidth : '1px') +
        ' solid ' +
        (this.borderColor ? this.borderColor : '#AAA'),
      'border-radius': this.cornerRadius ? this.cornerRadius : '50%',
    };
  };
}
