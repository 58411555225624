import { Component, OnInit, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import { CometChat } from '@cometchat-pro/chat';
import { ApiContact } from './../../../core/models/api-contact';
import { CometchatService } from 'src/app/core/providers/cometchat.service';

// Helper
import { Helper } from '../../helpers/helper';

@Component({
  selector: 'cometchat-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss'],
})
export class UserViewComponent implements OnInit {
  @Input() user: ApiContact | any;
  @Input() selected?: boolean;

  @Output() itemClick = new EventEmitter();

  nativeRef: ElementRef;
  userStatus = 'indeterminate';
  cometChatUser: any;

  constructor(elm: ElementRef, private cometChatService: CometchatService) {
    this.nativeRef = elm;
  }

  get avatarUser() {
    return this.user.avatar
      ? this.user.avatar
      : Helper.getSVGAvatar(
          this.user.uid,
          this.getUserName().substr(0, 1).toUpperCase()
        );
  }

  ngOnInit() {
    this.getStatus();
  }

  onItemClick() {
    this.itemClick.emit(this.cometChatUser);
  }

  private getStatus() {
    this.cometChatService
      .getUserByUid(this.user.uid)
      .then((user: any) => {
        this.userStatus = user.status;
        this.cometChatUser = user;
      }).catch((error) => {
        if (error && error.code && error.code === 'ERR_UID_NOT_FOUND') {
          // this.createUser();
        }
      });
  }

  private createUser() {
    const params = this.createParamsForUser();
    this.cometChatService.createUser(params).then((result: any) => {
      this.userStatus = result.status;
      this.cometChatUser = result;
    });
  }

  private createParamsForUser() {
    const params = {
      uid: this.user.uid,
      name: this.user.displayName || this.user.name,
    };

    if (this.user.avatar) {
      return { ...params, avatar: this.user.avatar };
    }

    return params;
  }

  private getUserName() {
    return this.user.displayName || this.user.name || '';
  }
}
