import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Emoji
import { EmojiModule, EmojiService } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import {
  PickerModule,
  EmojiFrequentlyService,
  EmojiSearch,
} from '@ctrl/ngx-emoji-mart';

// Routing
import { CometchatRoutingModule } from './cometchat-routing.module';

// Pages
import { HomeComponent } from './pages/home/home.component';

// Components
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { BottomNavigationComponent } from './components/bottom-navigation/bottom-navigation.component';
import { ConversationsListComponent } from './components/conversations-list/conversations-list.component';
import { ContactListComponent } from './components/contact-list/contact-list.component';
import { UserViewComponent } from './components/user-view/user-view.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { StatusIndicatorComponent } from './components/status-indicator/status-indicator.component';
import { ConversationScreenComponent } from './components/conversation-screen/conversation-screen.component';
import { ConversationViewComponent } from './components/conversation-view/conversation-view.component';
import { BadgeComponent } from './components/badge/badge.component';
import { ConversationHeaderComponent } from './components/conversation-header/conversation-header.component';
import { MessagesListComponent } from './components/messages-list/messages-list.component';
import { MessageBubbleComponent } from './components/message-bubble/message-bubble.component';
import { MediaMessageComposerPreviewComponent } from './components/media-message-composer-preview/media-message-composer-preview.component';
import { MessageComposerComponent } from './components/message-composer/message-composer.component';
import { EntityDetailsComponent } from './components/entity-details/entity-details.component';
import { MediaMessageComposerComponent } from './components/media-message-composer/media-message-composer.component';
import { FullScreenIframeComponent } from './components/full-screen-iframe/full-screen-iframe.component';
import { LoadingComponent } from './components/loading/loading.component';

import { ScrollingModule } from '@angular/cdk/scrolling';

// Pipes
import { SafePipe } from './pipes/safe.pipe';
import { CallingScreenComponent } from './components/calling-screen/calling-screen.component';

const MODULES = [EmojiModule, PickerModule];

const PROVIDERS = [EmojiService, EmojiFrequentlyService, EmojiSearch];

const COMPONENTS = [
  HomeComponent,
  SidebarComponent,
  ConversationScreenComponent,
  BottomNavigationComponent,
  ConversationsListComponent,
  ContactListComponent,
  UserViewComponent,
  AvatarComponent,
  StatusIndicatorComponent,
  ConversationViewComponent,
  BadgeComponent,
  ConversationHeaderComponent,
  MessagesListComponent,
  MessageBubbleComponent,
  MediaMessageComposerPreviewComponent,
  MessageComposerComponent,
  EntityDetailsComponent,
  MediaMessageComposerComponent,
  FullScreenIframeComponent,
  LoadingComponent,
];

const PIPES = [SafePipe];

@NgModule({
  declarations: [...COMPONENTS, ...PIPES, CallingScreenComponent],
  imports: [CommonModule, CometchatRoutingModule, ScrollingModule, ...MODULES],
  providers: [...PROVIDERS],
})
export class CometchatModule {}
