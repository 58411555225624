import { ApiContact } from './../models/api-contact';
import { Injectable } from '@angular/core';
import { CometChat } from '@cometchat-pro/chat';

// Environment
import { environment } from 'src/environments/environment';
import { User } from '../models/user';

@Injectable()
export class CometchatService {
  private _contactList: CometChat.User[] = [];

  constructor() {}

  set contactList(contactList: CometChat.User[]) {
    this._contactList = contactList;
  }

  get contactList(): CometChat.User[] {
    return this._contactList;
  }

  initCometChat(): Promise<boolean> {
    const appSetting = new CometChat.AppSettingsBuilder()
      .setRegion(environment.cometChat.region)
      .subscribePresenceForAllUsers()
      .build();

    return CometChat.init(environment.cometChat.appId, appSetting);
  }

  login(uid: string): Promise<CometChat.User> {
    return CometChat.login(uid, environment.cometChat.apiKey);
  }

  logout(): Promise<Object> {
    return CometChat.logout();
  }

  getLoggedinUser(): Promise<CometChat.User> {
    return CometChat.getLoggedinUser();
  }

  getUserByUid(uid: string): Promise<CometChat.User> {
    return CometChat.getUser(uid);
  }

  updateUser(user: CometChat.User): Promise<CometChat.User> {
    return CometChat.updateUser(user, environment.cometChat.apiKey);
  }

  createUser(user: any): Promise<CometChat.User> {
    return CometChat.createUser(user, environment.cometChat.apiKey);
  }
}
