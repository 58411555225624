import { ApiContact } from './../../../core/models/api-contact';
import {
  Component,
  OnInit,
  HostListener,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { CometChat } from '@cometchat-pro/chat';

// RxJS
import { catchError, debounceTime, takeUntil } from 'rxjs/operators';
import { Subject, of } from 'rxjs';

// Manager
import { ContactListManager } from '../../managers/cometchat-contact-list-manager';

// Const
import {
  CONTACT_LIST_ACTIONS,
  USER_LIST_ACTIONS,
  STRING_CONSTS,
} from '../../const/constants';

// Helper
import { Helper } from '../../helpers/helper';

// Services
import { ApiService } from 'src/app/core/providers/api.service';
import { CometchatService } from 'src/app/core/providers/cometchat.service';

@Component({
  selector: 'cometchat-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss'],
})
export class ContactListComponent implements OnInit, OnDestroy {
  @Input() selectedUser?;
  @Input() friendsOnly? = false;

  @Input() onItemSelect?: (user: CometChat.User, type?: string) => void;
  @Output() actionPerformed = new EventEmitter<{
    action: string;
    payload?: object;
  }>();

  messageToDisplay = STRING_CONSTS.STRING_MESSAGES.LOADING_MESSSAGE;
  contactListManager: ContactListManager;
  users: CometChat.User[] = [];
  filteredUsers: CometChat.User[] = [];
  JSONParser = JSON;
  searchStarted?;
  querySub = new Subject();

  private unsubscribe = new Subject();

  constructor(
    private cdRef: ChangeDetectorRef,
    private apiService: ApiService,
    private cometChatService: CometchatService
  ) {}


  onItemClick(user) {
    if (this.onItemSelect) {
      this.onItemSelect(user, 'user');
    }
    this.actionPerformed.emit({
      action: CONTACT_LIST_ACTIONS.CONTACT_ITEM_SELECTED,
      payload: { user },
    });

    this.selectedUser = user; // set the selectedUser to the item clicked.
  };

  ngOnInit() {
    if (typeof this.selectedUser === 'string' && !(this.selectedUser === '')) {
      let tempUser = new CometChat.User({});
      this.selectedUser = Object.assign(
        tempUser,
        JSON.parse(this.selectedUser) as CometChat.User
      ) as CometChat.User;
    }

    this.contactListManager = new ContactListManager(
      undefined,
      this.friendsOnly
    );
    this.init();
    this.applySearch();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  init() {
    this.contactListManager.isLoggedIn(this.isChatReady);
    this.contactListManager.attachListener(this.callback);
  }

  applySearch() {
    this.querySub.asObservable()
      .pipe(
        takeUntil(this.unsubscribe),
        debounceTime(300)
      ).subscribe((query: string) => {
        if (!query) {
          this.filteredUsers = this.users;
        } else {
          const inputValue = query.toLowerCase();
          this.filteredUsers = this.users.filter((user: any) =>
            user.displayName.toLowerCase().includes(inputValue)
          );

          if (!this.filteredUsers.length) {
            this.messageToDisplay =
              STRING_CONSTS.STRING_MESSAGES.ERROR_NO_USERS_FOUND;
          }
        }
      });
  }

  onSearchChange(event: any) {
    this.querySub.next(event.target.value);
  }

  printContactIndex(i: number, user: ApiContact) {
    if (i > 0) {
      const previousUser = this.filteredUsers[i - 1] as any;

      return previousUser.displayName.substring(0, 1).toLowerCase() ===
          user.displayName.substring(0, 1).toLowerCase()
          ? ''
          : user.displayName.substring(0, 1).toUpperCase();
    }
    return '';
  }

  @HostListener('scroll', ['$event.target'])
  onScroll(elem: any) {
    // if (
    //   elem.target.offsetHeight + elem.target.scrollTop + 1 >=
    //   elem.target.scrollHeight
    // ) {
    //   this.contactListManager.fetchNext().then(
    //     (users: CometChat.User[]) => {
    //       users.map((contact, i) => {
    //         if (!contact.getAvatar()) {
    //           users[i] = this.setAvatar(contact);
    //         }
    //       });
    //       this.users = [...this.users, ...users];
    //     },
    //     (err) => {
    //       this.messageToDisplay =
    //         STRING_CONSTS.STRING_MESSAGES.ERROR_LOADING_USERS;
    //     }
    //   );
    // }
  }

  fetchNext() {
    this.contactListManager.fetchNext().then(
      (users: CometChat.User[]) => {
        if (users.length > 0) {
          users.map((contact, i) => {
            if (!contact.getAvatar()) {
              users[i] = this.setAvatar(contact);
            }
          });
          this.users = users;
        } else {
          this.messageToDisplay =
            STRING_CONSTS.STRING_MESSAGES.ERROR_NO_USERS_FOUND;
          this.users = [];
        }
      },
      (error) => {
        this.messageToDisplay =
          STRING_CONSTS.STRING_MESSAGES.ERROR_LOADING_USERS;
      }
    );
  }

  isChatReady = (user?: CometChat.User, error?) => {
    if (user) {
      this.apiService
        .getContactList()
        .pipe(
          catchError(() => {
            this.messageToDisplay =
              STRING_CONSTS.STRING_MESSAGES.ERROR_LOADING_USERS;
            return of(null);
          })
        )
        .subscribe((contactList: any) => {
          this.users = contactList;
          this.filteredUsers = this.users;
        });
    } else {
      this.messageToDisplay =
        STRING_CONSTS.STRING_MESSAGES.ERROR_COMETCHAT_LOGIN;
    }
  };

  callback = (event: {
    action: string;
    payload?: { onlineUser?: CometChat.User; offlineUser?: CometChat.User };
  }) => {
    switch (event.action) {
      case USER_LIST_ACTIONS.USER_STATUS_CHANGED.ONLINE:
        this.users.map((user, i) => {
          if (
            user.getUid().toString() ===
            event.payload.onlineUser.getUid().toString()
          ) {
            this.users[i] = this.setAvatar(event.payload.onlineUser);
            this.cdRef.detectChanges();
          }
        });
        break;
      case USER_LIST_ACTIONS.USER_STATUS_CHANGED.OFFLINE:
        this.users.map((user, i) => {
          if (
            user.getUid().toString() ===
            event.payload.offlineUser.getUid().toString()
          ) {
            this.users[i] = this.setAvatar(event.payload.offlineUser);
            this.cdRef.detectChanges();
          }
        });
        break;
    }
  };

  private setAvatar(contact) {
    if (!contact.getAvatar()) {
      contact.setAvatar(
        Helper.getSVGAvatar(contact.getUid(), contact.getName().substr(0, 1))
      );
    }
    return contact;
  }

  private updateUser(user: CometChat.User) {
    this.cometChatService.updateUser(user).then(() => {});
  }
}
