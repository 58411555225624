import { Injectable } from '@angular/core';

@Injectable()
export class AudioService {
  private audio: HTMLAudioElement;

  constructor() {
    this.audio = new Audio('./assets/mp3/ringtone-incoming-call.mp3');
    this.setAttributes();
  }

  play(): void {
    this.audio.play();
  }

  stop(): void {
    this.audio.pause();
  }

  private setAttributes(): void {
    this.audio.loop = true;
  }
}
