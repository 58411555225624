import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CometChat } from '@cometchat-pro/chat';
import { DeviceDetectorService } from 'ngx-device-detector';

// Services
import { AuthService } from 'src/app/core/providers/auth.service';
import { CometchatService } from '../../../core/providers/cometchat.service';

// Managers
import { CometChatMainManager } from '../../managers/cometchat-main-manager';

// Contants
import {
  CALL_SCREEN_ACTIONS,
  SIDEBAR_ACTIONS,
  NAVIGATION_MENU_ACTIONS,
  CONVERSATIONS_SCREEN_ACTIONS,
} from '../../const/constants';

@Component({
  selector: 'mv-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @Input() friendsOnly? = false;

  isOpenedSidebar = true;
  showLoading = false;

  user?: CometChat.User;
  json = JSON;
  inProgressCall;
  incomingScreen;
  cometchatManager: CometChatMainManager = new CometChatMainManager();
  sidebarActions: { action: string; payload?: object | any } = { action: '' };

  constructor(
    private cdRef: ChangeDetectorRef,
    private cometChatService: CometchatService,
    private authService: AuthService,
    private router: Router,
    private deviceService: DeviceDetectorService
  ) {}

  ngOnInit() {
    this.checkIfMobile();
    this.initCometChat();

    this.cometchatManager.isLoggedIn(() => {
      this.cometchatManager.attachListener((event) => {
        switch (event.action) {
          case CALL_SCREEN_ACTIONS.INCOMING_CALL_RECEIVED: {
            this.incomingScreen = false;
            setTimeout(() => {
              this.incomingScreen = true;
              this.inProgressCall = JSON.stringify(event.payload.call);
              this.cdRef.detectChanges();
            }, 300);
            this.cdRef.detectChanges();
            break;
          }
          case CALL_SCREEN_ACTIONS.INCOMING_CALL_CANCELLED: {
            this.incomingScreen = false;
            this.inProgressCall = JSON.stringify(event.payload.call);
            this.cdRef.detectChanges();
            break;
          }
        }
      });
    });
  }

  handleCallScreenActions = (event) => {
    let tempUser = this.user;
    this.user = undefined;
    this.cdRef.detectChanges();
    this.user = tempUser;
    this.cdRef.detectChanges();
  }

  handleSidebarEvents(event) {
    switch (event.action) {
      case SIDEBAR_ACTIONS.ITEM_SELECTED:
        const type = event.payload.type;
        const item = event.payload.item;
        if (type === 'group') {
          this.user = undefined;
        } else {
          this.user = item;
        }
        break;
      case SIDEBAR_ACTIONS.CHAT_OPTIONS:
        // TODO handle an event
        break;
      case SIDEBAR_ACTIONS.HELP_OPTIONS:
        // TODO handle an event
        break;
      case SIDEBAR_ACTIONS.MORE_INFO_ITEM_SELECTED:
        // TODO handle an event
        break;
      case SIDEBAR_ACTIONS.NOTIFICATION_OPTIONS:
        // TODO handle an event
        break;
      case SIDEBAR_ACTIONS.PRIVACY_AND_SECURITY_OPTION:
        // TODO handle an event
        break;
      case SIDEBAR_ACTIONS.REPORT_A_PROBLEM:
        // TODO handle an event
        break;
      case NAVIGATION_MENU_ACTIONS.TAB_CHANGED:
        // TODO handle tab changed
        break;
    }
    this.cdRef.detectChanges();
  }

  handleActionByConversationScreen = (event: {
    action: string;
    payload?: object | any;
  }) => {
    switch (event.action) {
      case CONVERSATIONS_SCREEN_ACTIONS.MESSAGES_COMPOSER_ACTIONS
        .MESSAGE_SENT: {
        this.sidebarActions = event;
        break;
      }
      case CONVERSATIONS_SCREEN_ACTIONS.MEDIA_MESSAGES_COMPOSER_ACTIONS
        .MEDIA_MESSAGE_SENT: {
        this.sidebarActions = event;
        break;
      }
      case CONVERSATIONS_SCREEN_ACTIONS.ADD_MEMBERES_CONTS.ACTIONS
        .MEMBERS_ADDED: {
        this.sidebarActions = event;
        break;
      }
      case CONVERSATIONS_SCREEN_ACTIONS.CONVERSATION_SCREEN_HEADER_ACTIONS
        .AUDIO_CALL_STARTED: {
        this.inProgressCall = JSON.stringify(event.payload.outGoingCall);

        break;
      }
      case CONVERSATIONS_SCREEN_ACTIONS.CONVERSATION_SCREEN_HEADER_ACTIONS
        .VIDEO_CALL_STARTED: {
        this.inProgressCall = JSON.stringify(event.payload.outGoingCall);

        break;
      }
    }

    this.cdRef.detectChanges();
  }

  changeOpenedSidebar(event: boolean) {
    this.isOpenedSidebar = event;
  }

  changeStatusLoading(event: boolean) {
    this.showLoading = event;
  }

  private initCometChat() {
    this.cometChatService.initCometChat().then(
      () => this.loginUserCometChat(),
      () => this.router.navigate(['error'])
    );
  }

  private checkIfMobile() {
    if (!this.deviceService.isDesktop()) {
      this.isOpenedSidebar = !this.isOpenedSidebar;
    }
  }

  private loginUserCometChat() {
    const user = this.authService.getUser();

    if (user && user.uid) {
      this.cometChatService.login(user.uid).then(
        (userLogged: CometChat.User) => {
          // No same user that logged at the start
          // this.user = userLogged;
        },
        () => this.router.navigate(['error'])
      );
    } else {
      this.router.navigate(['error']);
    }
  }

}
