import { User } from '../models/user';

export function createUser(decodeJwt: any, jwt: string): User {
  return {
    authToken: decodeJwt.authToken,
    displayName: decodeJwt.displayName,
    role: decodeJwt.role,
    pvo: decodeJwt.video,
    pcl: decodeJwt.voice,
    uid: decodeJwt.uid,
    jwt,
  };
}
