import {
  Component,
  Input,
  ChangeDetectorRef,
  OnChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import { CometChat } from '@cometchat-pro/chat';

// Constants
import { MESSAGES_COMPOSER_ACTIONS } from '../../const/constants';

@Component({
  selector: 'cometchat-message-composer',
  templateUrl: './message-composer.component.html',
  styleUrls: ['./message-composer.component.scss'],
})
export class MessageComposerComponent implements OnChanges {
  placeHolder = 'Mensaje';

  // constants
  TOGLE_EMOJI = MESSAGES_COMPOSER_ACTIONS.CLICK_TOGGLE_EMOJI;
  OPTION_MENU = MESSAGES_COMPOSER_ACTIONS.CLICK_OPTION_MENU;
  RECORD_AUDIO = MESSAGES_COMPOSER_ACTIONS.CLICK_RECORD_AUDIO;
  SEND_MESSAGE = MESSAGES_COMPOSER_ACTIONS.CLICK_SEND_MESSAGE;

  typingStarted: any;
  timer = 0;
  fileOptionsExpanded = false;

  showEmojiKeyboard = false;
  @Input() user?;
  @Input() text = this.placeHolder;

  @Output() actionPerformed = new EventEmitter<{
    action: string;
    payload?: CometChat.BaseMessage;
  }>();

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnChanges() {
    this.init();
  }

  init() {
    if (this.user) {
      this.user = JSON.parse(this.user);
    }

    this.cdRef.detectChanges();
  }

  onActive = (event) => {
    if (event.target.innerText === this.placeHolder) {
      event.target.innerText = '';
    }
  };

  onBlur = (event) => {
    if (event.target.innerText.trim() === '') {
      event.target.innerText = this.placeHolder;
    }
  };

  onActionGenerated(event, action) {
    if (action === this.OPTION_MENU) {
      this.fileOptionsExpanded = !this.fileOptionsExpanded;
    }
    if (action === this.SEND_MESSAGE) {
      document.getElementById('cometchat-message-composer').focus();
      this.showEmojiKeyboard = false;

      let receiverID;
      let receiverType;

      if (this.user) {
        receiverID = this.user.uid;
        receiverType = CometChat.RECEIVER_TYPE.USER;
      }

      let typingNotification = new CometChat.TypingIndicator(
        receiverID,
        receiverType
      );

      CometChat.endTyping(typingNotification);

      const messageText = document
        .getElementById('cometchat-message-composer')
        .innerText.trim();

      const textMessage = new CometChat.TextMessage(
        receiverID,
        messageText,
        receiverType
      );

      document.getElementById('cometchat-message-composer').innerText = '';
      this.cdRef.detectChanges();

      CometChat.sendMessage(textMessage).then(
        (message: CometChat.BaseMessage) => {
          this.actionPerformed.emit({
            action: MESSAGES_COMPOSER_ACTIONS.MESSAGE_SENT,
            payload: message,
          });
        },
        (error) => {
          this.actionPerformed.emit({
            action: MESSAGES_COMPOSER_ACTIONS.ERROR_IN_MESSAGE_SENDING,
            payload: error,
          });
        }
      );

      return;
    }

    if (action === this.TOGLE_EMOJI) {
      this.showEmojiKeyboard = !this.showEmojiKeyboard;
    }

    this.actionPerformed.emit({ action });
  }

  onTypingStarted($event: any) {
    this.showEmojiKeyboard = false;
    if (this.typingStarted) {
    } else {
      let receiverID;
      let receiverType;

      if (this.user) {
        receiverID = this.user.uid;
        receiverType = CometChat.RECEIVER_TYPE.USER;
      }

      let typingNotification = new CometChat.TypingIndicator(
        receiverID,
        receiverType
      );

      CometChat.startTyping(typingNotification);

      this.typingStarted = setTimeout(() => {
        clearTimeout(this.typingStarted);
        this.typingStarted = undefined;
      }, 5000);
    }
  }

  onchangeDetect = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      this.showEmojiKeyboard = false;
      let receiverID;
      let receiverType;

      if (this.user) {
        receiverID = this.user.uid;
        receiverType = CometChat.RECEIVER_TYPE.USER;
      }

      let typingNotification = new CometChat.TypingIndicator(
        receiverID,
        receiverType
      );

      CometChat.endTyping(typingNotification);

      this.typingStarted = setTimeout(() => {
        clearTimeout(this.typingStarted);
        this.typingStarted = undefined;
      }, 5000);
      const messageText = event.srcElement.innerText.trim();
      const textMessage = new CometChat.TextMessage(
        receiverID,
        messageText,
        receiverType
      );
      event.srcElement.innerText = '';
      this.cdRef.detectChanges();
      CometChat.sendMessage(textMessage).then(
        (message: CometChat.BaseMessage) => {
          this.actionPerformed.emit({
            action: MESSAGES_COMPOSER_ACTIONS.MESSAGE_SENT,
            payload: message,
          });
        },
        (error) => {
          this.actionPerformed.emit({
            action: MESSAGES_COMPOSER_ACTIONS.ERROR_IN_MESSAGE_SENDING,
            payload: error,
          });
        }
      );

      return false;
    } else {
      this.onTypingStarted(event);
    }
  };

  clickOnEmoji(event) {
    document.getElementById('cometchat-message-composer').focus();
    document.getElementById('cometchat-message-composer').innerText =
      document.getElementById('cometchat-message-composer').innerText +
      event.emoji.native;
    let tag = document.getElementById('cometchat-message-composer');

    var setpos = document.createRange();

    var set = window.getSelection();

    setpos.setStart(
      tag.childNodes[0],
      document.getElementById('cometchat-message-composer').innerText.length
    );

    setpos.collapse(true);

    set.removeAllRanges();

    set.addRange(setpos);
    document.getElementById('cometchat-message-composer').focus();
  }
}
