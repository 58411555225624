import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'media-message-composer',
  templateUrl: './media-message-composer.component.html',
  styleUrls: ['./media-message-composer.component.scss'],
})
export class MediaMessageComposerComponent {
  listItems = [
    {
      title: 'Video',
      icon: '../../assets/icons/videocam-24px.svg',
      id: 'video',
    },
    {
      title: 'Audio',
      icon: '../../assets/icons/audiotrack-24px.svg',
      id: 'audio',
    },
    {
      title: 'File',
      icon: '../../assets/icons/insert_drive_file-24px.svg',
      id: 'file',
    },
    { title: 'Image', icon: '../../assets/icons/image-24px.svg', id: 'image' },
  ];

  @Input() callBack?;

  @Output() mediaOptionSelected = new EventEmitter<{
    item: object;
    id: string;
  }>();

  constructor() {}

  onItemClick = (event, item) => {
    this.mediaOptionSelected.emit({ item, id: item.id });
  };
}
