import {
  Component,
  OnInit,
  HostListener,
  Input,
  ChangeDetectorRef,
  OnChanges,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { CometChat } from '@cometchat-pro/chat';

// Manager
import { ConversationListManager } from '../../managers/cometchat-conversation-list-manager';

// Const
import {
  CONVERSATION_LIST_ACTIONS,
  CONVERSATIONS_SCREEN_ACTIONS,
  USER_LIST_ACTIONS,
} from '../../const/constants';

// Helper
import { Helper } from '../../helpers/helper';

@Component({
  selector: 'cometchat-conversations-list',
  templateUrl: './conversations-list.component.html',
  styleUrls: ['./conversations-list.component.scss'],
})
export class ConversationsListComponent implements OnChanges, OnInit {
  JSONParser = JSON;

  conversationManager: ConversationListManager;
  decoratorMessage = 'CARGANDO...';

  @Input() selectedConversation?: CometChat.Conversation | any;
  @Input() selectedUser?;
  @Input() updatedConversations?: CometChat.Conversation[] | any;
  @Input() conversations?: CometChat.Conversation[] | any = [];
  @Input() actionRequired: { action: string; payload?: object | any };

  @Output() actionPerformed = new EventEmitter<{
    action: string;
    payload?: object;
  }>();

  constructor(private cdRef: ChangeDetectorRef) {}

  onConversationClick = (conversation: CometChat.Conversation) => {
    this.selectedConversation = conversation; // setting the selected conversation.
    if (conversation.getLastMessage()) {
      this.callback(conversation.getLastMessage(), false, false);
    }

    if (conversation.getConversationType() === 'user') {
      this.actionPerformed.emit({
        action: CONVERSATION_LIST_ACTIONS.CONVERSATION_ITEM_SELECTED,
        payload: {
          user: conversation.getConversationWith(),
        },
      });
    }
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes.actionRequired) {
      let event = changes.actionRequired.currentValue;

      if (event) {
        switch (event.action) {
          case CONVERSATIONS_SCREEN_ACTIONS.MESSAGES_COMPOSER_ACTIONS
            .MESSAGE_SENT:
            CometChat.CometChatHelper.getConversationFromMessage(
              event.payload.message
            ).then((updatedConversation: CometChat.Conversation) => {
              let found: boolean = false;
              updatedConversation = this.setProfileImage(updatedConversation);
              this.conversations.map(
                (conversation: CometChat.Conversation, i: number) => {
                  if (
                    conversation.getConversationId() ===
                    updatedConversation.getConversationId()
                  ) {
                    found = true;
                    if (
                      this.selectedConversation &&
                      this.selectedConversation.getConversationId() ===
                        updatedConversation.getConversationId()
                    ) {
                      updatedConversation.setUnreadMessageCount(0);
                    } else {
                      // tslint:disable-next-line: radix
                      updatedConversation.setUnreadMessageCount(
                        conversation.getUnreadMessageCount() + 1
                      );
                    }
                    updatedConversation.setConversationWith(
                      this.conversations[i].getConversationWith()
                    );

                    this.conversations.splice(i, 1);

                    this.conversations = [
                      updatedConversation,
                      ...this.conversations,
                    ];
                  }
                }
              );
              if (!found) {
                this.selectedConversation = updatedConversation;
                this.conversations = [
                  updatedConversation,
                  ...this.conversations,
                ];
              }
              this.cdRef.detectChanges();
            });

            break;
          case CONVERSATIONS_SCREEN_ACTIONS.MEDIA_MESSAGES_COMPOSER_ACTIONS
            .MEDIA_MESSAGE_SENT:
            CometChat.CometChatHelper.getConversationFromMessage(
              event.payload.message
            ).then((updatedConversation: CometChat.Conversation) => {
              updatedConversation = this.setProfileImage(updatedConversation);
              this.conversations.map(
                (conversation: CometChat.Conversation, i: number) => {
                  if (
                    conversation.getConversationId() ===
                    updatedConversation.getConversationId()
                  ) {
                    if (
                      this.selectedConversation &&
                      this.selectedConversation.getConversationId() ===
                        updatedConversation.getConversationId()
                    ) {
                      updatedConversation.setUnreadMessageCount(0);
                    } else {
                      // tslint:disable-next-line: radix
                      updatedConversation.setUnreadMessageCount(
                        conversation.getUnreadMessageCount() + 1
                      );
                    }

                    this.conversations.splice(i, 1);
                    this.conversations = [
                      updatedConversation,
                      ...this.conversations,
                    ];
                  }
                }
              );
              this.cdRef.detectChanges();
            });
            break;
        }
      }
    }
    if (
      this.updatedConversations &&
      !(this.updatedConversations instanceof Object)
    ) {
      const updatedConversations: CometChat.Conversation[] = [] as CometChat.Conversation[];
      const conversationsJsonArray: [] = JSON.parse(this.conversations);
      conversationsJsonArray.map((conversation: CometChat.Conversation) => {
        const conv = new CometChat.Conversation(
          undefined,
          undefined,
          undefined,
          undefined,
          undefined
        );
        Object.assign(conv, conversation);
        updatedConversations.push(conv);
      });

      this.updatedConversations = updatedConversations;
      this.conversations = [...this.conversations, ...updatedConversations];
    }

    if (this.conversations && !(this.conversations instanceof Object)) {
      const conversations: CometChat.Conversation[] = [] as CometChat.Conversation[];
      const conversationsJsonArray: [] = JSON.parse(this.conversations);
      conversationsJsonArray.map((conversation: CometChat.Conversation) => {
        const conv = new CometChat.Conversation(
          undefined,
          undefined,
          undefined,
          undefined,
          undefined
        );
        Object.assign(conv, conversation);
        conversations.push(conv);
      });

      this.conversations = conversations;
    }

    if (
      this.selectedConversation &&
      !(this.selectedConversation instanceof Object)
    ) {
      const conv = new CometChat.Conversation(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      );
      Object.assign(conv, JSON.parse(this.selectedConversation));
      this.selectedConversation = conv;
    }
  }

  ngOnInit() {
    if (this.selectedUser) {
      if (
        typeof this.selectedUser === 'string' &&
        !(this.selectedUser === '')
      ) {
        let tempUser = new CometChat.User({});
        this.selectedUser = Object.assign(
          tempUser,
          JSON.parse(this.selectedUser) as CometChat.User
        ) as CometChat.User;
      }
    }

    this.conversationManager = new ConversationListManager();
    this.init();
  }

  init() {
    this.conversationManager.isLoggedIn(this.isChatReady);
    this.conversationManager.attachListener((message, isReceipt) => {
      this.callback(message, isReceipt);
    });
    this.conversationManager.attachUserListener((event) =>
      this.userListernerCallback(event)
    );
  }

  callback(message, isReceipt, makeFirst = true) {
    if (!isReceipt) {
      CometChat.CometChatHelper.getConversationFromMessage(message).then(
        (updatedConversation: CometChat.Conversation) => {
          updatedConversation = this.setProfileImage(updatedConversation);
          if (this.conversations.length > 0) {
            const finded = this.conversations.find(
              (conversation: CometChat.Conversation) => {
                return (
                  conversation.getConversationId() ===
                  updatedConversation.getConversationId()
                );
              }
            );

            if (!finded) {
              this.conversations = [updatedConversation, ...this.conversations];
            }

            this.conversations.map(
              (conversation: CometChat.Conversation, i: number) => {
                if (
                  conversation.getConversationId() ===
                  updatedConversation.getConversationId()
                ) {
                  updatedConversation.setConversationWith(
                    conversation.getConversationWith()
                  );
                  if (
                    this.selectedConversation &&
                    this.selectedConversation.getConversationId() ===
                      updatedConversation.getConversationId()
                  ) {
                    updatedConversation.setUnreadMessageCount(0);
                  } else {
                    // tslint:disable-next-line: radix
                    updatedConversation.setUnreadMessageCount(
                      conversation.getUnreadMessageCount() + 1
                    );
                  }
                  if (makeFirst) {
                    this.conversations.splice(i, 1);
                    this.conversations = [
                      updatedConversation,
                      ...this.conversations,
                    ];
                  } else {
                    this.conversations[i] = updatedConversation;
                  }
                }
              }
            );
          } else {
            this.conversations = [updatedConversation];
          }
          this.cdRef.detectChanges();
        }
      );
    }
  }

  userListernerCallback(event) {
    switch (event.action) {
      case USER_LIST_ACTIONS.USER_STATUS_CHANGED.ONLINE:
        this.conversations.map((conversation, i) => {
          if (conversation.conversationType === 'user') {
            let user = conversation.conversationWith;
            if (
              user.uid.toString() === event.payload.onlineUser.uid.toString()
            ) {
              conversation.setConversationWith(event.payload.onlineUser);
              conversation = this.setProfileImage(conversation);
              this.conversations[i] = conversation;
            }
          }
        });
        break;
      case USER_LIST_ACTIONS.USER_STATUS_CHANGED.OFFLINE:
        this.conversations.map((conversation, i) => {
          if (conversation.conversationType === 'user') {
            let user = conversation.conversationWith;
            if (
              user.uid.toString() === event.payload.offlineUser.uid.toString()
            ) {
              conversation.setConversationWith(event.payload.offlineUser);
              conversation = this.setProfileImage(conversation);
              this.conversations[i] = conversation;
            }
          }
        });
        break;
    }
    this.cdRef.detectChanges();
  }

  @HostListener('scroll', ['$event.target'])
  onScroll(elem) {
    if (
      elem.target.offsetHeight + elem.target.scrollTop >=
      elem.target.scrollHeight
    ) {
      this.conversationManager.fetchNext().then(
        (conversations: CometChat.Conversation[]) => {
          conversations.map((conversation, i) => {
            conversation = this.setProfileImage(conversation);
            conversations[i] = conversation;
            if (conversation.getConversationType() === 'user') {
              if (this.selectedUser)
                if (
                  (conversation.getConversationWith() as CometChat.User).getUid() ===
                  (this.selectedUser as CometChat.User).getUid()
                ) {
                  this.selectedConversation = conversation;
                }
            }
          });

          this.conversations = [...this.conversations, ...conversations];
        },
        (error) => {}
      );
    }
  }

  setProfileImage(conversation) {
    if (conversation.getConversationType() == 'user') {
      if (!(conversation.getConversationWith() as CometChat.User).getAvatar()) {
        (conversation.getConversationWith() as CometChat.User).setAvatar(
          Helper.getSVGAvatar(
            (conversation.getConversationWith() as CometChat.User).getUid(),
            conversation
              .getConversationWith()
              .getName()
              .substr(0, 1)
              .toUpperCase()
          )
        );
      }
    }

    return conversation;
  }

  isChatReady = (user?: CometChat.User, error?) => {
    if (user) {
      this.conversationManager.fetchNext().then(
        (conversations: CometChat.Conversation[]) => {
          if (conversations.length === 0) {
            this.decoratorMessage = 'Please initiate conversation with a user';
          } else {
          }
          conversations.map((conversation, i) => {
            conversation = this.setProfileImage(conversation);
            conversations[i] = conversation;
            if (conversation.getConversationType() === 'user') {
              if (this.selectedUser)
                if (
                  (conversation.getConversationWith() as CometChat.User).getUid() ===
                  (this.selectedUser as CometChat.User).getUid()
                ) {
                  this.selectedConversation = conversation;
                }
            }
          });

          this.conversations = conversations;
          if (this.conversations && this.conversations.length) {
            this.onConversationClick(this.conversations[0]);
          }
        },
        (err) => {}
      );
    }
  };
}
