import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Services
import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';

// Environment
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  getContactList() {
    const jwt = this.getJwtUser();
    const endpoint = `${environment.api.url}/v2.0/contacts/${jwt}`;
    //const endpoint = `https://app.mootiv.app/api/v2.0/contacts/${jwt}`;

    return this.http.get(endpoint);
  }

  private getJwtUser(): string {
    return this.authService.getUser().jwt;
  }

}
