import { Injectable } from '@angular/core';

// Models
import { User } from '../models/user';

@Injectable()
export class AuthService {
  constructor() {}

  saveUser(user: User) {
    const encoded = btoa(JSON.stringify(user));
    localStorage.setItem('mvc', encoded);
  }

  getUser(): User {
    try {
      return JSON.parse(atob(localStorage.getItem('mvc')));
    } catch {
      return null;
    }
  }
}
