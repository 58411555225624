import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

// RxJS
import { Subject, interval } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

// Service
import { AuthService } from './core/providers/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';

// Models
import { User } from './core/models/user';

// Utils
import { createUser } from './core/utils/user-token.utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private helper = new JwtHelperService();

  private unsubscribe = new Subject();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnDestroy() {
    this.removeSubscriptions();
  }

  ngOnInit() {
    this.checkBrowserRefresh();
    this.initParams();
  }

  private initParams() {
    this.checkParams();
    this.subscribeToParams();
  }

  private subscribeToParams() {
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((params: any) => {
        if (params.jwt) {
          this.removeSubscriptions();
          this.decodeSaveToken(params.jwt);
        }
      });
  }

  private checkBrowserRefresh() {
    // If user do browser refresh, we won't check params again and navigate to conversations page
    this.router.events
      .pipe(
        filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd),
        takeUntil(this.unsubscribe)
      )
      .subscribe((event) => {
        if (event.id === 1 && event.url === event.urlAfterRedirects) {
          this.removeSubscriptions();
          this.router.navigate(['conversations']);
        }
      });
  }

  private checkParams() {
    // If after two seconds the url don't have the parameter JWT we will navigate to error page
    interval(2500)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.removeSubscriptions();
        this.router.navigate(['error']);
      });
  }

  private decodeSaveToken(jwtParam: string) {
    const decodeJwt = this.helper.decodeToken(jwtParam);
    const user: User = createUser(decodeJwt, jwtParam);
    this.authService.saveUser(user);

    this.router.navigate(['conversations']);
  }

  private removeSubscriptions() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
