import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'conversations',
    loadChildren: './cometchat/cometchat.module#CometchatModule',
  },
  {
    path: 'error',
    loadChildren: './handle-error/handle-error.module#HandleErrorModule',
  },
  {
    path: 'logout',
    loadChildren: './handle-logout/handle-logout.module#HandleLogoutModule',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
