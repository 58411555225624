import {
  Component,
  Input,
  OnChanges,
  ChangeDetectorRef,
  EventEmitter,
  Output,
  OnInit,
} from '@angular/core';
import { CometChat } from '@cometchat-pro/chat';
import { DeviceDetectorService } from 'ngx-device-detector';

// Managers
import { ConversationHeaderManager } from '../../managers/cometchat-conversation-header-manager';

// Const
import { CONVERSATION_SCREEN_HEADER_ACTIONS } from '../../const/constants';

// Services
import { AuthService } from 'src/app/core/providers/auth.service';
import { AudioService } from 'src/app/core/providers/audio.service';

// Helper
import { Helper } from '../../helpers/helper';
import { User } from 'src/app/core/models/user';

@Component({
  selector: 'cometchat-conversation-header',
  templateUrl: './conversation-header.component.html',
  styleUrls: ['./conversation-header.component.scss'],
})
export class ConversationHeaderComponent implements OnInit, OnChanges {
  @Input() user?;

  @Output() actionOpenedSidebar = new EventEmitter<boolean>();
  @Output() actionPerformed = new EventEmitter<{
    action: String;
    payload: Object;
  }>();

  isOpenedSidebar = true;
  status = undefined;
  showCallingScreen = false;
  inProgressCall;
  conversationHeaderManager: ConversationHeaderManager;

  userLogged: User;

  constructor(
    private cdRef: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
    private authService: AuthService,
    private ringTone: AudioService
  ) {}

  get avatarUser() {
    return this.user.avatar
      ? this.user.avatar
      : Helper.getSVGAvatar(
          this.user.uid,
          this.getUserName().substr(0, 1).toUpperCase()
        );
  }

  get isMyConversationSelected(): boolean {
    return this.user && this.user.uid === this.userLogged.uid;
  }

  get isVoiceVisible(): boolean {
    return this.userLogged && this.userLogged.pcl;
  }

  get isVideoVisible(): boolean {
    return this.userLogged && this.userLogged.pvo;
  }

  ngOnInit() {
    if (!this.deviceService.isDesktop()) {
      this.isOpenedSidebar = !this.isOpenedSidebar;
    }
  }

  ngOnChanges() {
    if (this.user && !(this.user instanceof Object)) {
      this.user = JSON.parse(this.user);
      this.status = this.user.status;
      this.conversationHeaderManager = new ConversationHeaderManager(
        this.user.uid
      );
      this.conversationHeaderManager.isLoggedIn(this.isChatReady);
      this.conversationHeaderManager.attachListener(this.callback);

      this.userLogged = this.authService.getUser();

      CometChat.getUser(this.user.uid.toString()).then((user) => {
        if ((user.getStatus() as string).toLowerCase() === 'online') {
          this.status = user.getStatus();
        } else {
          this.status = undefined;
          this.user.lastActiveAt = user.getLastActiveAt();
        }
      });
    }
  }

  makeAudioCall = ($event) => {
    this.ringTone.play();

    let receiverID;
    let receiverType;
    if (this.user) {
      receiverID = this.user.uid;
      receiverType = CometChat.RECEIVER_TYPE.USER;
    }

    const callType = CometChat.CALL_TYPE.AUDIO;
    const call = new CometChat.Call(receiverID, callType, receiverType);

    CometChat.initiateCall(call).then(
      (outGoingCall) => {
        this.showCallingScreen = true;

        this.actionPerformed.emit({
          action: CONVERSATION_SCREEN_HEADER_ACTIONS.VIDEO_CALL_STARTED,
          payload: { outGoingCall },
        });
      },
      (error) => {
        this.actionPerformed.emit({
          action: CONVERSATION_SCREEN_HEADER_ACTIONS.AUDIO_CALL_STARTED,
          payload: { error },
        });
      }
    );
  };

  makeVideoCall = ($event) => {
    this.ringTone.play();

    let receiverID;
    let receiverType;
    if (this.user) {
      receiverID = this.user.uid.toString();
      receiverType = CometChat.RECEIVER_TYPE.USER;
    }

    const callType = CometChat.CALL_TYPE.VIDEO;
    const call = new CometChat.Call(receiverID, callType, receiverType);

    CometChat.initiateCall(call).then(
      (outGoingCall) => {
        this.showCallingScreen = true;

        this.actionPerformed.emit({
          action: CONVERSATION_SCREEN_HEADER_ACTIONS.VIDEO_CALL_STARTED,
          payload: { outGoingCall },
        });
      },
      (error) => {
        this.actionPerformed.emit({
          action: CONVERSATION_SCREEN_HEADER_ACTIONS.AUDIO_CALL_STARTED,
          payload: { error },
        });
      }
    );
  };

  clickMenuOption = ($event) => {
    if (this.user) {
      this.actionPerformed.emit({
        action: CONVERSATION_SCREEN_HEADER_ACTIONS.USER_OPTION_MENU_SELECTED,
        payload: { user: this.user },
      });
    }
  };

  callback = (event: { action: string; payload?: object | any }) => {
    switch (event.action) {
      case CONVERSATION_SCREEN_HEADER_ACTIONS.USER_STATUS_CHANGED.ONLINE:
        this.user = event.payload.onlineUser;
        this.status = this.user.status;
        this.cdRef.detectChanges();
        break;
      case CONVERSATION_SCREEN_HEADER_ACTIONS.USER_STATUS_CHANGED.OFFLINE:
        this.user = event.payload.offlineUser;
        this.status = this.user.status;
        this.cdRef.detectChanges();
        break;
      case CONVERSATION_SCREEN_HEADER_ACTIONS.TYPING_STATUS_CHANGED
        .TYPING_STARTED: {
        let typingIndicator: CometChat.TypingIndicator = event.payload
          .typingIndicator as CometChat.TypingIndicator;
        if (
          typingIndicator.getReceiverType() === 'user' &&
          typingIndicator.getSender().getUid().toString() ===
            this.user.uid.toString()
        ) {
          this.status = 'typing...';
        }
        break;
      }
      case CONVERSATION_SCREEN_HEADER_ACTIONS.TYPING_STATUS_CHANGED
        .TYPING_ENDED: {
        let typingIndicator: CometChat.TypingIndicator = event.payload
          .typingIndicator as CometChat.TypingIndicator;
        if (
          typingIndicator.getReceiverType() === 'user' &&
          typingIndicator.getSender().getUid().toString() ===
            this.user.uid.toString()
        ) {
          this.status = this.user.status;
        }
        break;
      }
    }

    try {
      this.cdRef.detectChanges();
    } catch (e) {}
  };

  isChatReady = (user?: CometChat.User, error?) => {
    if (user) {
      // TODO set the current logged in user.
    } else {
      // TODO show error that cometchat user log in is failed.
    }
  };

  changeOpenedSidebar() {
    this.isOpenedSidebar = !this.isOpenedSidebar;
    this.actionOpenedSidebar.emit(this.isOpenedSidebar);
  }

  private getUserName() {
    return this.user.displayName || this.user.name || '';
  }
}
