import { Component, Input, OnChanges } from '@angular/core';
import { CometChat } from '@cometchat-pro/chat';

@Component({
  selector: 'cometchat-conversation-view',
  templateUrl: './conversation-view.component.html',
  styleUrls: ['./conversation-view.component.scss'],
})
export class ConversationViewComponent implements OnChanges {
  @Input() conversation?: CometChat.Conversation | any;
  @Input() selected: boolean = true;

  name?: string;
  image?: string;
  text?: string;
  count = 0;
  conversationType: string;
  status: string;

  constructor() {}

  ngOnChanges() {
    if (typeof this.conversation === 'string') {
      this.conversation = Object.assign(
        JSON.parse(this.conversation) as CometChat.Conversation
      );
      this.name = this.conversation.conversationWith.name;
      this.count = this.conversation.unreadMessageCount;
      this.conversationType = this.conversation.conversationType;
      this.status = this.conversation.conversationWith.status;
      switch (this.conversation.conversationType) {
        case 'user': {
          this.image = this.conversation.conversationWith.avatar;
          break;
        }
      }

      if (this.conversation.lastMessage) {
        switch (this.conversation.lastMessage.category) {
          case 'message':
            switch (this.conversation.lastMessage.type) {
              case CometChat.MESSAGE_TYPE.TEXT: {
                this.text = this.conversation.lastMessage.text;
                break;
              }
              case CometChat.MESSAGE_TYPE.MEDIA: {
                this.text = 'Mensaje Multimedia';
                break;
              }
              case CometChat.MESSAGE_TYPE.IMAGE: {
                this.text = 'Imagen';
                break;
              }
              case CometChat.MESSAGE_TYPE.FILE: {
                this.text = 'Archivo';
                break;
              }
              case CometChat.MESSAGE_TYPE.VIDEO: {
                this.text = 'Video';
                break;
              }
              case CometChat.MESSAGE_TYPE.AUDIO: {
                this.text = 'Audio';
                break;
              }
              case CometChat.MESSAGE_TYPE.CUSTOM: {
                this.text = 'Mensaje Custom';
                break;
              }
            }
            break;
          case 'call':
          case CometChat.MESSAGE_TYPE.VIDEO: {
            this.text = 'Llamada de video';
            break;
          }
          case CometChat.MESSAGE_TYPE.AUDIO:
            {
              this.text = 'Llamada de audio';
              break;
            }
            break;
          case 'action':
            this.text = this.conversation.lastMessage.message;
            break;
          case 'custom':
            this.text = 'mensaje custom';
            break;
        }
      } else {
        this.text = 'Último mensaje no encontrado';
      }
    }
  }
}
